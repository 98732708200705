<template>
  <div class="member-tab">
    <div id="table-box">
      <div class="search">
        <search-input
          v-model="search"
          v-bind:submitted="searchSubmitted"
          v-bind:returnValue="memberSearch"
          :suggestions="suggestions"
          v-bind:returnSuggestion="suggestion"
        ></search-input>
        <div class="search--filters">
          <multiselect
            class="multi-select filter-user-status-multi-select"
            label="title"
            track-by="id"
            v-model="verificationStatus.selected"
            selectLabel
            deselectLabel
            selectedLabel
            :options="verificationStatus.options"
            :option-width="80"
            :option-height="20"
            :searchable="false"
            :allowEmpty="true"
            open-direction="bottom"
            @input="verificationStatusFilterChanged"
          >
            <template v-slot:singleLabel="props">
              <span class="option__title">{{ props.option.title }}</span>
              <img src="../assets/multiselect-arrow.png" class="arrow" />
            </template>
            <template v-slot:option="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.title }}</span>
              </div>
            </template>
          </multiselect>

          <multiselect
            class="multi-select filter-role-multi-select"
            label="title"
            track-by="id"
            v-model="role.selected"
            selectLabel
            deselectLabel
            selectedLabel
            :options="role.options"
            :option-width="80"
            :option-height="20"
            :searchable="false"
            :allowEmpty="true"
            open-direction="bottom"
            @input="roleFilterChanged"
          >
            <template v-slot:singleLabel="props">
              <span class="option__title">{{ props.option.title }}</span>
              <img src="../assets/multiselect-arrow.png" class="arrow" />
            </template>
            <template v-slot:option="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.title }}</span>
              </div>
            </template>
          </multiselect>

          <multiselect
            class="multi-select filter-trailer-multi-select"
            label="title"
            track-by="id"
            v-model="trailer.selected"
            selectLabel
            deselectLabel
            selectedLabel
            :options="trailer.options"
            :option-width="80"
            :option-height="20"
            :searchable="false"
            :allowEmpty="true"
            open-direction="bottom"
            @input="trailerFilterChanged"
          >
            <template v-slot:singleLabel="props">
              <span class="option__title">{{ props.option.title }}</span>
              <img src="../assets/multiselect-arrow.png" class="arrow" />
            </template>
            <template v-slot:option="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.title }}</span>
              </div>
            </template>
          </multiselect>

          <multiselect
            class="multi-select filter-truck-multi-select"
            label="title"
            track-by="id"
            v-model="truck.selected"
            selectLabel
            deselectLabel
            selectedLabel
            :options="truck.options"
            :option-width="80"
            :option-height="20"
            :searchable="false"
            :allowEmpty="true"
            open-direction="bottom"
            @input="truckFilterChanged"
          >
            <template v-slot:singleLabel="props">
              <span class="option__title">{{ props.option.title }}</span>
              <img src="../assets/multiselect-arrow.png" class="arrow" />
            </template>
            <template v-slot:option="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.title }}</span>
              </div>
            </template>
          </multiselect>
        </div>

        <div class="search--buttons">
          <div v-if="!adding">
            <button
              class="button button-primary"
              id="add-new"
              v-on:click="openAdd"
            >
              <img src="@/assets/plus-circle.svg" alt="plus sign in circle" />
              Add
            </button>
            <button
              class="button button-primary"
              :disabled="!selectedUsers.length"
              v-bind:class="{ superAdmin: $session.isSuperAdmin }"
              id="bulk-update"
              v-on:click="bulkUpdate(selectedUsers)"
            >
              <img src="@/assets/import.png" alt="import arrow" />
              Bulk Update
            </button>
          </div>
          <div v-if="adding">
            <button
              class="button button-primary"
              id="invite-user"
              v-on:click="showAddUser()"
            >
              <img src="@/assets/invite.svg" alt="email invite user" />
              Invite User
            </button>
            <button
              class="button button-primary"
              id="bulk-import"
              v-on:click="bulkImport()"
            >
              <img src="@/assets/import.png" alt="import arrow" />
              Bulk Import
            </button>
          </div>
        </div>
      </div>
      <div id="table-controls"></div>
      <table id="table">
        <thead id="member-header">
          <tr id="table-header">
            <th>
              <input type="checkbox" @change="selectUnselectAll($event)" />
            </th>
            <th
              v-for="column in filterColumns"
              v-bind:key="column.id"
              class="noselect"
              v-bind:class="column.id"
            >
              <span class="column-title" v-on:click="setSortBy(column.id)">
                {{ column.title }}
              </span>
              <div
                class="arrow"
                v-bind:class="computedSortClass(column.id)"
                v-on:click="setSortBy(column.id)"
              ></div>
            </th>
            <th title="menu"></th>
          </tr>
        </thead>

        <tbody v-if="paginatedData.length > 0">
          <tr
            class="table-row"
            v-for="(user, index) in paginatedData"
            v-bind:key="user.id"
            v-bind:class="computedBlockedClass(user.roles[0].status)"
          >
            <td>
              <input
                type="checkbox"
                v-bind:value="user.id"
                v-model="selectedUsers"
              />
            </td>
            <td>{{ user.firstName }}</td>
            <td>{{ user.lastName }}</td>
            <td>{{ user.email }}</td>
            <!--trucks--->
            <td class="equipment equipment--truck">
              <div v-on:click="handleTruckClick(index, user)">
                {{ user.assignedTrucksString }}
              </div>
              <dropdown
                :key="index"
                :ref="`truck${index}`"
                top="50px"
                left="20px"
                arrow="left"
                v-if="user.truck && user.truck.length > 1"
              >
                <p
                  v-for="t in user.truck"
                  :key="t"
                  v-on:click="editEquipment(t)"
                >
                  {{ t.title.substr(0, 8) }}
                </p>
              </dropdown>
            </td>
            <!--trucks--->
            <!--trailers--->
            <td class="equipment equipment--trailer">
              <div v-on:click="handleTrailerClick(index, user)">
                {{ user.assignedTrailersString }}
              </div>
              <dropdown
                :key="index"
                :ref="`trailer${index}`"
                arrow="left"
                top="50px"
                left="20px"
                v-if="user.trailer && user.trailer.length > 1"
              >
                <p
                  v-for="t in user.trailer"
                  :key="t"
                  v-on:click="editEquipment(t)"
                >
                  {{ t.title.substr(0, 8) }}
                </p>
              </dropdown>
            </td>
            <!--roles--->
            <td :class="user.roleInfo.classVal">
              <div class="role-title">{{ user.primaryRole }}</div>
              <div class="driver-icon"></div>
            </td>

            <td>{{ user.roleStatus }}</td>
            <td class="last-activity">{{ user.lastActivity }}</td>
            <td
              v-if="user.id != loggedInUser.id"
              v-on:click.prevent="
                $refs[`itemMenu${index}`][0].changeMenuStatus()
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="6"
                viewBox="0 0 26 6"
              >
                <g fill="#124E81" fill-rule="evenodd">
                  <circle cx="3" cy="3" r="3" />
                  <circle cx="13" cy="3" r="3" />
                  <circle cx="23" cy="3" r="3" />
                </g>
              </svg>
              <dropdown
                :key="index"
                :ref="`itemMenu${index}`"
                top="50px"
                left="-129px"
              >
                <p class="dropdown-item" v-on:click="showUserEdit(user)">
                  Edit
                </p>
                <p v-on:click="showConfirmBlock(user)" class="dropdown-item">
                  {{ disableAccountAction(user.roles[0].status) }}
                </p>
                <p class="dropdown-item" v-on:click="showConfirmDelete(user)">
                  Remove
                </p>
                <p class="dropdown-item" v-on:click="showVerifyUser(user.id)">
                  Verify User
                </p>
                <p class="dropdown-item" v-on:click="resendInvite(user)">
                  Resend Invite
                </p>
              </dropdown>
            </td>
            <td v-else v-on:click="showUserEdit(user)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#124E81"
                  fill-rule="nonzero"
                  d="M2 17.834V22h4.166L18.453 9.713l-4.166-4.166L2 17.834zM21.675 6.49a1.106 1.106 0 0 0 0-1.566l-2.6-2.6a1.106 1.106 0 0 0-1.566 0l-2.033 2.033 4.166 4.166 2.033-2.033z"
                />
              </svg>
            </td>
          </tr>
        </tbody>
      </table>
      <PaginationControls
        v-on:changePage="pageChange"
        :pageNumber="pagination.pageNumber"
        :pageMax="maxPage"
      />
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/DropDownMenu.vue'
import PaginationControls from '@/components/PaginationControls.vue'
import SearchInput from '@/components/SearchInput.vue'
import userEditIcon from '@/assets/user-bg.svg'
import TrashIcon from '@/assets/trash.svg'
import TruckIcon from '@/assets/truck.png'
import TrailerIcon from '@/assets/trailer.png'
import Multiselect from 'vue-multiselect'
import Util from '../service/util'

export default {
  name: 'memberTable',
  components: {
    PaginationControls,
    Dropdown,
    SearchInput,
    Multiselect
  },
  props: ['companyId'],
  data() {
    return {
      selectedUsers: [],
      adding: false,
      isSuperAdmin: false,
      columns: [
        {
          id: 'firstName',
          title: 'First Name'
        },
        {
          id: 'lastName',
          title: 'Last Name'
        },
        {
          id: 'email',
          title: 'Email'
        },
        {
          id: 'assignedTrucksString',
          title: 'Truck'
        },
        {
          id: 'assignedTrailersString',
          title: 'Trailer'
        },
        {
          id: 'primaryRole',
          title: 'Role'
        },
        {
          id: 'roleStatus',
          title: 'Status'
        },
        {
          id: 'lastActivity',
          title: 'Last Activity'
        }
      ],
      allUsers: this.$userStore.users,
      sortBy: 'firstName',
      sortDirection: 'up',
      pagination: {
        pageLimit: 20,
        pageNumber: 1
      },
      totalCount: this.$userStore.totalUsers,
      search: '',
      loggedInUser: [],
      suggestions: [],
      verificationStatus: {
        selected: {
          title: 'Verification Status',
          id: 'blank'
        },
        options: [
          {
            title: 'Verified',
            id: 'verified'
          },
          {
            title: 'Not Verified',
            id: 'notVerified'
          }
        ]
      },
      role: {
        selected: {
          title: 'Role',
          id: 'blank'
        },
        options: [
          {
            title: 'Org Admin',
            id: 'org_admin'
          },
          {
            title: 'Manager',
            id: 'acc_admin'
          },
          {
            title: 'Driver',
            id: 'driver'
          }
        ]
      },
      trailer: {
        selected: {
          title: 'Trailer',
          id: 'blank'
        },
        options: [
          {
            title: 'Assigned',
            id: 'assigned'
          },
          {
            title: 'Unassigned',
            id: 'unassigned'
          }
        ]
      },
      truck: {
        selected: {
          title: 'Truck',
          id: 'blank'
        },
        options: [
          {
            title: 'Assigned',
            id: 'assigned'
          },
          {
            title: 'Unassigned',
            id: 'unassigned'
          }
        ]
      }
    }
  },
  created() {
    this.isSuperAdmin = this.$session.isSuperAdmin
    this.loggedInUser = this.$session.currentUser
    this.refreshList()
    this.$events.$on('users_refresh', (data) =>
      this.refreshList(data.users, data.totalUsers)
    )
    this.$events.$on('search_focus', () => {
      this.adding = false
    })
    this.$events.$on('user_added', () => this.refreshList())
    console.log(this)
  },
  computed: {
    filterColumns: function () {
      return this.columns.filter((column) => {
        //if super admin, show account status column
        return this.isSuperAdmin ? true : column.id != 'accountStatus'
      })
    },
    maxPage: function () {
      return Math.ceil(this.totalCount / this.pagination.pageLimit)
    },
    computedBlockedClass: function () {
      return function (status) {
        return status === 'blocked' ? 'blocked' : ''
      }
    },
    computedSortClass: function () {
      return function (sortId) {
        if (this.sortBy === sortId) {
          return 'sorting ' + this.sortDirection
        } else {
          return ''
        }
      }
    },
    paginatedData: function () {
      let newData = []
      let startItem =
        this.pagination.pageLimit * (this.pagination.pageNumber - 1)
      let endItem = startItem + this.pagination.pageLimit

      // Sort
      this.$util.sortColumns(this.allUsers, this.sortBy, this.sortDirection)
      // Return list
      for (startItem; startItem < endItem; startItem++) {
        if (this.allUsers[startItem]) {
          newData.push(this.allUsers[startItem])
        }
      }
      return newData
    },
    disableAccountAction: function () {
      return function (accountStatus) {
        if (accountStatus === 'unblocked') {
          return 'Block'
        } else {
          return 'Unblock'
        }
      }
    },
    accountStatus: function () {
      return function (accountStatus) {
        if (accountStatus === 'unblocked') {
          return 'Active'
        } else {
          return 'Blocked'
        }
      }
    },
    getFormattedDate: function () {
      return function (timeStamp) {
        if (!timeStamp) return 'Never'

        let date = new Date(timeStamp)

        if (date.getFullYear() === 1969) {
          return 'Never'
        }
        return this.$moment(timeStamp).format('MM/DD/YYYY')
      }
    }
  },
  watch: {
    $route() {
      this.adding = false
    }
  },
  methods: {
    selectUnselectAll: function (e) {
      if (e.target.checked) {
        //select all
        for (let user of this.paginatedData) {
          this.selectedUsers.push(user?.id)
        }
      } else {
        //unselect all
        this.resetSelectedUsers()
      }
    },
    resetSelectedUsers: function () {
      this.selectedUsers = []
    },
    bulkUpdate: async function (userIds) {
      this.$events.$emit('showBulkActionsModal', {
        userIds,
        callback: (result) => {
          this.$events.$emit('hideBulkActionsModal')
          if (result) {
            this.$notify({
              group: 'admin-actions',
              title: 'Updated Account',
              text: `Bulk Action performed`,
              data: {
                iconPath: userEditIcon
              }
            })
          }
        }
      })
    },
    showVerifyUser: function (userId) {
      console.log(userId)
      let fullName = ''
      this.$userStore.getUserName(userId).then((name) => {
        fullName = name

        this.$events.$emit('showBasicModal', {
          mode: 'confirm',
          title: 'Manual Verification',
          text: `Are you sure you want to manually verify ${fullName}?`,
          secondaryText: 'This action cannot be undone',
          callback: (resp) => {
            if (resp) {
              this.$events.$emit('showLoading')
              this.$userStore
                .manuallyVerifyUser(userId)
                .then(() => {
                  this.$events.$emit('hideLoading')

                  this.$notify({
                    group: 'admin-actions',
                    title: 'Manually Verified Account',
                    text: `${fullName}'s account was successfully verified`,
                    data: {
                      iconPath: userEditIcon
                    }
                  })
                  this.refreshList()
                })
                .catch((err) => {
                  this.$events.$emit('error', err)

                  return err
                })
            }
          },
          cancelText: 'Cancel',
          confirmText: 'Verify'
        })
      })
    },
    resendInvite: function (user) {
      console.log(user)
      this.$events.$emit('showBasicModal', {
        mode: 'confirm',
        title: 'Re Send Invite',
        text: `Resend invite to ${user.firstName} ${user.lastName} at ${user.email}?`,
        callback: (resp) => {
          if (resp) {
            this.$events.$emit('showLoading')
            this.$client
              .resendInvite(user.id, user.roleInfo?.primaryRole.toLowerCase())
              .then((result) => {
                this.$events.$emit('hideLoading')
                console.log(result)

                this.$notify({
                  group: 'admin-actions',
                  title: 'Message Sent',
                  text: `Message sent to ${user.email}`,
                  data: {
                    iconPath: userEditIcon
                  }
                })
                this.refreshList()
              })
              .catch((err) => {
                this.$events.$emit('error', err)

                return err
              })
          }
        },
        cancelText: 'Cancel',
        confirmText: 'Send'
      })
    },
    getUserRole: function (user) {
      if (user.roles.length === 0) return 'Error'
      let primaryRole = ''
      let classVal = ''
      let hasDriver = false
      for (let i = 0; i < user.roles.length; i++) {
        if (user.roles[i].role === 'org_admin') {
          classVal = 'org_admin'
          primaryRole = 'Org Admin'
        } else if (user.roles[i].role === 'acc_admin') {
          classVal = 'acc_admin'
          if (primaryRole === '') primaryRole = 'Manager'
        } else if (user.roles[i].role === 'driver') {
          hasDriver = true
        }
      }
      if (hasDriver) {
        classVal += ' has_driver'
        if (!primaryRole) {
          primaryRole = 'Driver'
        }
      }

      let lastActivity = 'None'
      user.roles.forEach((key) => {
        if (key.lastActivity && key.role === 'driver') {
          if (key.lastActivity !== 'none') {
            let date = new Date(key.lastActivity)
            lastActivity = `${
              date.getUTCMonth() + 1
            }/${date.getUTCDate()}/${date.getUTCFullYear()}`
          }
        }
      })

      user.lastActivity = lastActivity

      return {
        primaryRole: primaryRole,
        hasDriver: hasDriver,
        classVal: classVal
      }
    },
    openAdd: function () {
      this.$events.$emit('open_add', '')
      this.adding = true
    },
    handleTruckClick: function (index, user) {
      if (user.truck.length > 1) {
        this.$refs[`truck${index}`][0].changeMenuStatus()
      } else if (user.truck.length === 1) {
        this.editEquipment(user.truck[0])
      }
    },
    handleTrailerClick: function (index, user) {
      if (user.trailer.length > 1) {
        this.$refs[`trailer${index}`][0].changeMenuStatus()
      } else if (user.trailer.length === 1) {
        this.editEquipment(user.trailer[0])
      }
    },
    bulkImport: function () {
      this.$events.$emit('showImportModal', {
        title: 'Bulk Member Import',
        confirmText: 'Check',
        cancelText: 'Cancel',
        companyId: this.companyId,
        callback: async (confirmed, data) => {
          try {
            if (!confirmed) return
            let validation = await this.$userStore.validateUsers(
              data,
              this.companyId
            )
            return validation
          } catch (err) {
            this.$events.$emit('hideImportModal', err)
            this.$events.$emit('error', err)
          }
        }
      })
    },
    setSortBy: function (sortBy) {
      if (this.sortBy === sortBy) {
        if (this.sortDirection === 'up') {
          this.sortDirection = 'down'
        } else {
          this.sortDirection = 'up'
        }
      } else {
        this.sortDirection = 'up'
        this.sortBy = sortBy
      }
    },
    searchSubmitted: function () {
      this.refreshList()
    },
    memberSearch: async function (val) {
      try {
        this.sortBy = 'firstName'
        if (val === '') {
          this.suggestions = []
          this.refreshList()
          return
        }

        let usersArr = []

        this.allUsers.forEach((user) => {
          user.name = `${user.firstName} ${user.lastName}`
          user.points = 0
          if (val !== '') {
            let firstName = user.firstName.toLowerCase()
            let lastName = user.lastName.toLowerCase()
            let email = user.email.toLowerCase()
            let query = val.split(' ')
            query.forEach((key) => {
              user.points = user.points + (firstName.split(key).length - 1)
              user.points = user.points + (lastName.split(key).length - 1)
              user.points = user.points + (email.split(key).length - 1)
            })
          }

          if (user.points !== 0) {
            usersArr.push(user)
          }
        })

        if (usersArr.length === 0) {
          this.suggestions = [
            {
              id: 'none',
              name: 'No Results Found',
              points: 9999
            }
          ]

          return
        }

        const equipment = await this.$equipmentStore.loadAll(
          this.companyId,
          {
            equipmentType: '',
            assigned: '',
            query: ''
          },
          true
        )

        const combinedUsersEquipment = this.combineUserToAssignedEquipment(
          usersArr,
          equipment
        )

        this.suggestions = Util.sortByKey(
          combinedUsersEquipment,
          'points',
          true
        )
      } catch (err) {
        this.$events.$emit('hideLoading')
      }
    },
    suggestion: function (suggestion) {
      if (suggestion.id === 'none') return
      this.suggestions = []
      this.search = suggestion.name
      this.allUsers = [suggestion]
    },
    viewDocuments: function (ownerId) {
      this.$router.push(`/admin/documents/driver?ownerId=${ownerId}`)
    },
    combineUserToAssignedEquipment: function (users, equipment) {
      // Map the assignments for faster iteration
      let assignmentMap = {}
      equipment.forEach((item) =>
        item.assignments.forEach((assignment) => {
          if (!assignmentMap[assignment.driverId]) {
            assignmentMap[assignment.driverId] = {
              truck: [],
              trailer: []
            }
          }
          assignmentMap[assignment.driverId][item.type].push(item)
        })
      )

      users.forEach((user) => {
        user.truck = []
        user.trailer = []
        if (assignmentMap[user.id]) {
          user.truck = assignmentMap[user.id].truck
          user.trailer = assignmentMap[user.id].trailer
        }
        if (user.truck.length > 1) {
          user.assignedTrucksString = 'Multiple'
        } else if (user.truck.length === 1) {
          user.assignedTrucksString = user.truck[0].title.substr(0, 8)
        } else {
          user.assignedTrucksString = ''
        }

        if (user.trailer.length > 1) {
          user.assignedTrailersString = 'Multiple'
        } else if (user.trailer.length === 1) {
          user.assignedTrailersString = user.trailer[0].title.substr(0, 8)
        } else {
          user.assignedTrailersString = ''
        }
      })

      // Filter by truck and trailer assignments
      if (
        this.truck.selected.id !== 'blank' ||
        this.trailer.selected.id !== 'blank' ||
        this.verificationStatus.selected.id !== 'blank'
      ) {
        users = users.filter((user) => {
          if (this.verificationStatus.selected.id === 'verified') {
            return user.verificationCode === 'verified'
          } else if (this.verificationStatus.selected.id === 'notVerified') {
            return user.verificationCode !== 'verified'
          }

          if (
            this.truck.selected.id === 'assigned' &&
            user.truck.length === 0
          ) {
            return false
          } else if (
            this.truck.selected.id === 'unassigned' &&
            user.truck.length > 0
          ) {
            return false
          }

          if (
            this.trailer.selected.id === 'assigned' &&
            user.trailer.length === 0
          ) {
            return false
          } else if (
            this.trailer.selected.id === 'unassigned' &&
            user.trailer.length > 0
          ) {
            return false
          }

          return true
        })
      }
      return users
    },
    editEquipment: function (equipment) {
      // First get the previous assignments
      let previousAssignments = JSON.parse(
        JSON.stringify(equipment.assignments)
      )
      let parsedAssignments = []
      let prevAssignmentMap = {}
      for (let i = 0; i < previousAssignments.length; i++) {
        prevAssignmentMap[previousAssignments[i].driverId] =
          previousAssignments[i]
        parsedAssignments.push({
          id: previousAssignments[i].driverId,
          title:
            previousAssignments[i].driver.firstName +
            ' ' +
            previousAssignments[i].driver.lastName
        })
      }

      // Call them modal
      this.$events.$emit('showEquipmentModal', {
        title: equipment.type === 'trailer' ? 'Edit Trailer' : 'Edit Truck',
        unitNumber: equipment.title,
        companyId: equipment.ownerId,
        assignments: parsedAssignments,
        importType: equipment.type,
        callback: async (confirmed, data) => {
          try {
            if (!confirmed) return

            this.$events.$emit('showLoading')

            // Get the changes and update the equipment
            if (equipment.title !== data.unitNumber) {
              await this.$equipmentStore.updateEquipmentById(equipment.id, {
                title: data.unitNumber
              })
            }
            await this.$assignmentStore.addAssignment(
              equipment.id,
              data.assignments
            )

            this.refreshList()

            this.$notify({
              group: 'admin-actions',
              title:
                equipment.type !== 'trailer'
                  ? 'Truck Updated'
                  : 'Trailer Updated',
              text: `<span style="font-weight: 500;">${
                equipment.type !== 'trailer' ? 'Truck' : 'Trailer'
              } (${data.unitNumber})</span> was successfully updated`,
              data: {
                iconPath: equipment.type !== 'trailer' ? TruckIcon : TrailerIcon
              }
            })
          } catch (err) {
            this.$events.$emit('error', err)
          }
        }
      })
    },
    refreshList: async function (users, count) {
      try {
        this.$events.$emit('showLoading')
        if (typeof users != 'undefined' && users !== null) {
          this.totalCount = count < 1 ? 1 : count

          let trailerAssigned = this.trailer.selected.id
          if (trailerAssigned === 'blank') trailerAssigned = ''
          let truckAssigned = this.truck.selected.id
          if (truckAssigned === 'blank') truckAssigned = ''

          await this.$equipmentStore.loadAll(this.companyId, {
            equipmentType: '',
            trailerAssigned: trailerAssigned,
            truckAssigned: truckAssigned,
            query: this.search
          })

          const equip = this.$equipmentStore.equipment
          this.allUsers = this.combineUserToAssignedEquipment(users, equip)

          for (let i = 0; i < this.allUsers.length; i++) {
            this.allUsers[i].roleStatus = 'Active'
            for (let x = 0; x < this.allUsers[i].roles.length; x++) {
              if (this.allUsers[i].roles[x].status === 'blocked') {
                this.allUsers[i].roleStatus = 'Inactive'
                break
              }
            }
            let roleInfo = this.getUserRole(this.allUsers[i])
            this.allUsers[i].roleInfo = roleInfo
            this.allUsers[i].primaryRole = roleInfo.primaryRole
          }

          this.$events.$emit('hideLoading')
        } else {
          let role = this.role.selected.id
          if (role === 'blank') role = ''
          await this.$userStore.loadAll({
            companyId: this.companyId,
            role: role,
            query: this.search
          })

          this.pagination.pageNumber = 1
        }
      } catch (err) {
        this.$events.$emit('error', err)
      }
    },
    showAddUser: function () {
      this.$events.$emit('showInviteUserModal', {
        title: 'Invite User',
        companyId: this.companyId,
        isInvite: true,
        callback: () => {
          this.refreshList()
        }
      })
    },
    showUserEdit: async function (user) {
      let opts = {
        title: `${user.firstName} ${user.lastName}`,
        user: user,
        showCompanies: false,
        onlyCallback: true,
        callback: async (confirmed, data) => {
          try {
            if (!confirmed) return
            this.$events.$emit('showLoading')
            // let userUpdateSuccess = true
            let userChange = {
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              phoneNumber: data.phoneNumber,
              verificationCode: data.verificationCode,
              companyId: this.companyId,
              role: data.role.selected.role
            }
            let userSuccess = await this.$userStore.updateUserById(
              data.inviteUserModal.user.id,
              userChange
            )
            if (userSuccess) {
              let isDriver = false
              let isManager = false
              for (let role of user.roles) {
                if (role.role === 'driver') {
                  isDriver = true
                }
                if (role.role === 'acc_admin') {
                  isManager = true
                }
              }
              if (data.hasAppAccess) {
                if (!isDriver) {
                  //create driver role
                  await this.$userStore.createUserRole({
                    userId: userSuccess,
                    role: 'driver',
                    companyId: this.companyId
                  })
                }
                if (!isManager) {
                  //create manager role
                  await this.$userStore.createUserRole({
                    userId: userSuccess,
                    role: 'acc_admin',
                    companyId: this.companyId
                  })
                }
              } else {
                if (userChange.role === 'driver') {
                  if (!isDriver) {
                    //create driver role
                    await this.$userStore.createUserRole({
                      userId: userSuccess,
                      role: 'driver',
                      companyId: this.companyId
                    })
                  }
                  if (isManager) {
                    //load roles and delete manager
                    let currentRoles = await this.$userStore.readUserRoles(
                      userSuccess
                    )
                    for (let role of currentRoles) {
                      if (
                        role.role === 'acc_admin' &&
                        role.companyId === this.companyId
                      ) {
                        await this.$userStore.deleteRoleById(role.id)
                      }
                    }
                  }
                } else {
                  if (!isManager) {
                    //create manager role
                    await this.$userStore.createUserRole({
                      userId: userSuccess,
                      role: 'acc_admin',
                      companyId: this.companyId
                    })
                  }
                  if (isDriver) {
                    //load roles and delete driver
                    let currentRoles = await this.$userStore.readUserRoles(
                      userSuccess
                    )
                    for (let role of currentRoles) {
                      if (
                        role.role === 'driver' &&
                        role.companyId === this.companyId
                      ) {
                        await this.$userStore.deleteRoleById(role.id)
                      }
                    }
                  }
                }
              }
              this.$notify({
                group: 'admin-actions',
                title: 'Updated Account',
                text: `${data.firstName} ${data.lastName}'s information was successfully updated`,
                data: {
                  iconPath: userEditIcon
                }
              })
              this.refreshList()
            }
          } catch (err) {
            return err // Handled by user modal
          } finally {
            this.$events.$emit('hideLoading')
            this.refreshList()
          }
        }
      }
      this.$events.$emit('showUserModal', opts)
    },
    showConfirmDelete: function (user) {
      let fullName = ''
      this.$userStore.getUserName(user.id).then((name) => {
        fullName = name

        this.$events.$emit('showBasicModal', {
          mode: 'confirm',
          title: 'Confirmation',
          text: `Are you sure you want to remove ${fullName} from ${this.companyName}?`,
          secondaryText: 'This action cannot be undone',
          callback: async (resp) => {
            if (resp) {
              this.$events.$emit('showLoading')
              try {
                for (let i = 0; i < user.roles.length; i++) {
                  await this.$userStore.deleteRoleById(user.roles[i].id)
                }
                this.$events.$emit('hideLoading')

                this.refreshList()

                this.$notify({
                  group: 'admin-actions',
                  title: `Removed Account`,
                  text: `${fullName}'s account was successfully removed`,
                  data: {
                    iconPath: TrashIcon
                  }
                })
              } catch (err) {
                this.$events.$emit('error', err)
              }
            }
          },
          cancelText: 'Cancel',
          confirmText: 'Delete'
        })
      })
    },
    showConfirmBlock: function (user) {
      let newStatus = 'unblocked'
      let action = 'Unblock'
      let secondaryText
      let isDriver = false
      let isBlocked = false

      for (let role of user.roles) {
        if (role.role === 'driver') {
          isDriver = true
        }
        if (role.status === 'blocked') {
          isBlocked = true
        }
      }
      if (!isBlocked) {
        newStatus = 'blocked'
        action = 'Block'
      }

      if (!isDriver) {
        if (isBlocked) {
          secondaryText = 'They will be granted access to the platform.'
        } else {
          secondaryText = 'They will no longer have access to the platform.'
        }
      } else {
        if (isBlocked) {
          secondaryText =
            'Documents and assignments associated with this user will be accessible in the app.'
        } else {
          secondaryText =
            'Documents and assignments associated with this user will no longer be accessible in the app.'
        }
      }

      this.$events.$emit('showBasicModal', {
        mode: 'confirm',
        title: `Confirmation`,
        text: `Are you sure you want to ${action.toLowerCase()} this user?`,
        secondaryText: secondaryText,
        callback: async (resp) => {
          if (resp) {
            this.$events.$emit('showLoading')
            try {
              for (let i = 0; i < user.roles.length; i++) {
                await this.$userStore.updateRoleById(user.roles[i].id, {
                  status: newStatus
                })
              }
              this.$events.$emit('hideLoading')

              this.refreshList()

              this.$notify({
                group: 'admin-actions',
                title: `${action}ed Account`,
                text: `Account was successfully ${action}ed`,
                data: {
                  iconPath: TrashIcon
                }
              })
            } catch (err) {
              this.$events.$emit('error', err)
            }
          }
        },
        cancelText: 'Cancel',
        confirmText: action
      })
    },
    pageChange: function (newPage) {
      this.pagination.pageNumber = newPage
    },
    roleFilterChanged: function (newVal) {
      if (!newVal) {
        this.role.selected = {
          title: 'Role',
          id: 'blank'
        }
      }
      this.refreshList()
    },
    verificationStatusFilterChanged: function (newVal) {
      if (!newVal) {
        this.verificationStatus.selected = {
          title: 'Verification Status',
          id: 'blank'
        }
      }
      this.refreshList()
    },
    trailerFilterChanged: function (newVal) {
      if (!newVal) {
        this.trailer.selected = {
          title: 'Trailer',
          id: 'blank'
        }
      }
      this.refreshList()
    },
    truckFilterChanged: function (newVal) {
      if (!newVal) {
        this.truck.selected = {
          title: 'Truck',
          id: 'blank'
        }
      }
      this.refreshList()
    }
  }
}
</script>

<style lang="scss">
.member-tab {
  .last-activity {
    text-transform: capitalize;
  }

  #member-header {
    //is selected
    th:nth-child(1) {
      width: 5%;
    }
    //firstName
    th:nth-child(2) {
      width: 12%;
    }

    //lastName
    th:nth-child(3) {
      width: 12%;
    }

    //email
    th:nth-child(4) {
      width: 20%;
    }

    //truck
    th:nth-child(5) {
      width: 10%;
    }

    //trailer
    th:nth-child(6) {
      width: 11%;
    }

    //role
    th:nth-child(7) {
      width: 11%;
    }

    //status
    th:nth-child(8) {
      width: 8%;
    }

    // last activity
    th:nth-child(9) {
      width: 11%;
    }

    //menu
    th:nth-child(10) {
      width: 5%;
    }

    th .column-title {
      padding-right: 5px;
      cursor: pointer;
    }

    th .arrow {
      width: 13px;
      height: 8px;
      background-image: url('../assets/sort-arrow.png');
      display: none;
      cursor: pointer;
    }

    th .arrow.sorting {
      display: inline-block;
    }

    th .arrow.sorting.up {
      transform: rotate(180deg);
    }
  }

  .blocked {
    td {
      opacity: 0.2;
    }
    td:nth-child(4) {
      pointer-events: none;
    }
    td:nth-child(5) {
      pointer-events: none;
    }
    td:last-child {
      opacity: 1;
    }
  }

  .table-row {
    //truck
    td:nth-child(4) {
      width: 8%;
      color: #124e81;
      text-decoration: underline;
    }

    //trailer
    td:nth-child(5) {
      width: 11%;
      color: #124e81;
      text-decoration: underline;
    }

    //menu
    td:nth-child(8) {
      padding-bottom: 6px;
    }
  }

  .equipment {
    &:hover {
      cursor: pointer;
    }
  }

  .role-title {
    margin-top: 3px;
    float: left;
  }

  .driver-icon {
    width: 24px;
    height: 24px;
    float: left;
    background-image: url('../assets/driver_required.png');
  }
  .org_admin .driver-icon,
  .acc_admin .driver-icon {
    background-image: url('../assets/driver_passive.png');
  }

  .org_admin.has_driver .driver-icon,
  .acc_admin.has_driver .driver-icon {
    background-image: url('../assets/driver_active.png');
  }
}
</style>
