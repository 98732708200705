<template>
  <div id="organization" class="org-documents-page">
    <OrgTabs
      v-bind:tabs="tabs"
      :companyId="companyId"
      v-bind:initialTab="$route.params.type"
    />
  </div>
</template>
<script>
import OrgTabs from '@/components/OrgTabs.vue'

export default {
  components: {
    OrgTabs
  },
  data() {
    let tabs = [
      {
        title: 'Company',
        component: 'companyDocumentsTab',
        path: 'company'
      },
      {
        title: 'Drivers',
        component: 'driverDocumentsTab',
        path: 'drivers'
      },
      {
        title: 'Trucks',
        component: 'truckDocumentsTab',
        path: 'trucks'
      },
      {
        title: 'Trailers',
        component: 'trailerDocumentsTab',
        path: 'trailers'
      }
    ]

    return {
      tabs,
      companyId: this.$route.params.companyId
    }
  },
  created() {
    // Make sure the session is updated to track which page we're viewing
    this.$events.$on('admin_updated', (data) => {
      if (!data.currentCompany && !data.isSuperAdmin) {
        this.$session.updateCurrentCompany(this.companyId)
      }
    })
  },
  methods: {}
}
</script>

<style lang="scss">
@import '../scss/_table.scss';

.org-documents-page {
  #add-new {
    margin: 0px;
    width: 140px;
    z-index: 2;

    img {
      vertical-align: middle;
    }
  }
}
</style>
