<template>
  <div class="user-tab">
    <div id="table-box">
      <div class="search">
        <search-input
          v-model="search"
          v-bind:submitted="searchSubmitted"
          v-bind:returnValue="userSearch"
          :suggestions="suggestions"
          v-bind:returnSuggestion="suggestion"
        ></search-input>

        <div class="search--filters">
          <multiselect
            class="multi-select filter-status-multi-select"
            label="title"
            track-by="id"
            v-model="status.selected"
            selectLabel
            deselectLabel
            selectedLabel
            :options="status.options"
            :option-width="80"
            :option-height="20"
            :searchable="false"
            :allowEmpty="true"
            open-direction="bottom"
            @input="statusFilterChanged"
          >
            <template v-slot:singleLabel="props">
              <span class="option__title">{{ props.option.title }}</span>
              <img src="../assets/multiselect-arrow.png" class="arrow" />
            </template>
            <template v-slot:option="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.title }}</span>
              </div>
            </template>
          </multiselect>

          <multiselect
            class="multi-select filter-role-multi-select"
            label="title"
            track-by="id"
            v-model="role.selected"
            selectLabel
            deselectLabel
            selectedLabel
            :options="role.options"
            :option-width="80"
            :option-height="20"
            :searchable="false"
            :allowEmpty="true"
            open-direction="bottom"
            @input="roleFilterChanged"
          >
            <template v-slot:singleLabel="props">
              <span class="option__title">{{ props.option.title }}</span>
              <img src="../assets/multiselect-arrow.png" class="arrow" />
            </template>
            <template v-slot:option="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.title }}</span>
              </div>
            </template>
          </multiselect>
        </div>

        <div class="search--buttons">
          <button
            class="button button-primary"
            v-bind:class="{ superAdmin: $session.isSuperAdmin }"
            id="add-new-user"
            v-on:click="addNew"
          >
            <img src="@/assets/plus-circle.svg" alt="plus sign in circle" />
            New User
          </button>
          <button
            class="button button-primary"
            :disabled="!selectedUsers.length"
            v-bind:class="{ superAdmin: $session.isSuperAdmin }"
            id="bulk-update"
            v-on:click="bulkUpdate(selectedUsers)"
          >
            <img src="@/assets/import.png" alt="import arrow" />
            Bulk Update
          </button>
        </div>
      </div>
      <div id="table-controls"></div>
      <table id="table">
        <thead id="user-header">
          <tr id="table-header">
            <th>
              <input type="checkbox" @change="selectUnselectAll($event)" />
            </th>
            <th
              v-for="column in columns"
              v-bind:key="column.id"
              class="noselect"
              v-bind:class="column.id"
            >
              <span class="column-title" v-on:click="setSortBy(column.id)">
                {{ column.title }}
              </span>
              <div
                class="arrow"
                v-bind:class="computedSortClass(column.id)"
                v-on:click="setSortBy(column.id)"
              ></div>
            </th>
            <th title="menu"></th>
          </tr>
        </thead>
        <tbody v-if="paginatedData.length > 0">
          <tr
            class="table-row"
            v-for="(user, index) in paginatedData"
            v-bind:key="user.id"
          >
            <td>
              <input
                type="checkbox"
                v-bind:value="user.id"
                v-model="selectedUsers"
              />
            </td>
            <td>{{ user.firstName }}</td>
            <td>{{ user.lastName }}</td>
            <!-- <td>{{ user.phoneNumber }}</td> -->
            <td>{{ user.email }}</td>
            <td :class="user.companyName === '-' ? 'empty' : ''">
              <div v-on:click="handleUserCompanyClick(index, user)">
                {{ user.companyName }}
              </div>
              <dropdown
                :key="index"
                :ref="`user-company-${index}`"
                arrow="left"
                top="50px"
                left="20px"
                v-if="user.companies && user.companies.length > 1"
              >
                <p
                  v-for="t in user.companies"
                  :key="'user-company-' + t.id"
                  v-on:click="viewCompany(t)"
                >
                  {{ t.name.substr(0, 8) }}
                </p>
              </dropdown>
            </td>
            <td :class="user.roleInfo ? user.roleInfo.classVal : ''">
              <div class="role-title">
                {{ user.roleInfo ? user.roleInfo.primaryRole : '' }}
              </div>
              <div class="driver-icon" v-show="user.roleInfo"></div>
            </td>
            <td>{{ user.accountStatus }}</td>
            <td>{{ user.lastLoginFormatted }}</td>
            <td
              class="user-options"
              v-on:click.prevent="
                $refs[`itemMenu${index}`][0].changeMenuStatus()
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="6"
                viewBox="0 0 26 6"
              >
                <g fill="#124E81" fill-rule="evenodd">
                  <circle cx="3" cy="3" r="3" />
                  <circle cx="13" cy="3" r="3" />
                  <circle cx="23" cy="3" r="3" />
                </g>
              </svg>
              <dropdown
                :key="index"
                :ref="`itemMenu${index}`"
                top="50px"
                left="-129px"
              >
                <p class="dropdown-item" v-on:click="showUserEdit(user)">
                  View Account
                </p>
                <p
                  v-if="user.id != loggedInUser.id"
                  v-on:click="showConfirmBlock(user.id, user.accountStatus)"
                  class="dropdown-item"
                >
                  {{ disableAccountAction(user.accountStatus) }}
                </p>
                <p
                  v-if="user.id != loggedInUser.id"
                  class="dropdown-item"
                  v-on:click="showConfirmDelete(user.id)"
                >
                  Delete Account
                </p>
                <p
                  v-if="user.id != loggedInUser.id"
                  class="dropdown-item"
                  v-on:click="showVerifyUser(user.id)"
                >
                  Verify Account
                </p>
                <p
                  v-if="user.id != loggedInUser.id"
                  class="dropdown-item"
                  v-on:click="resendInvite(user)"
                >
                  Send Invite
                </p>
              </dropdown>
            </td>
          </tr>
        </tbody>
      </table>
      <PaginationControls
        v-on:changePage="pageChange"
        :pageNumber="pagination.pageNumber"
        :pageMax="maxPage"
      />
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/DropDownMenu.vue'
import PaginationControls from '@/components/PaginationControls.vue'
import SearchInput from '@/components/SearchInput.vue'
import userEditIcon from '@/assets/user-bg.svg'
import TrashIcon from '@/assets/trash.svg'
import CompanyIcon from '@/assets/company.svg'
import Multiselect from 'vue-multiselect'
import userAddIcon from '@/assets/user-outline.svg'

export default {
  name: 'userTable',
  components: {
    PaginationControls,
    Dropdown,
    SearchInput,
    Multiselect
  },
  data() {
    return {
      isSuperAdmin: false,
      adding: false,
      columns: [
        {
          id: 'firstName',
          title: 'First Name'
        },
        {
          id: 'lastName',
          title: 'Last Name'
        },
        // {
        //   id: 'phoneNumber',
        //   title: 'Phone'
        // },
        {
          id: 'email',
          title: 'Email'
        },
        {
          id: 'companyName',
          title: 'Company'
        },
        {
          id: 'primaryRole',
          title: 'Role'
        },
        {
          id: 'accountStatus',
          title: 'Status'
        },
        {
          id: 'lastLoginFormatted',
          title: 'Sign In'
        }
      ],
      allUsers: this.$userStore.users,
      sortBy: 'firstName',
      sortDirection: 'up',
      pagination: {
        pageLimit: 20,
        pageNumber: 1
      },
      totalCount: this.$userStore.totalUsers,
      search: '',
      selectedUsers: [],
      suggestions: [],
      loggedInUser: [],
      role: {
        selected: {
          title: 'Role',
          id: 'blank'
        },
        options: [
          {
            title: 'Super Admin',
            id: 'super_admin'
          },
          {
            title: 'Driver',
            id: 'driver'
          },
          {
            title: 'Org Admin',
            id: 'org_admin'
          },
          {
            title: 'Manager',
            id: 'acc_admin'
          }
        ]
      },
      status: {
        selected: {
          title: 'Status',
          id: 'blank'
        },
        options: [
          {
            title: 'Active',
            id: 'active'
          },
          {
            title: 'Inactive',
            id: 'inactive'
          },
          {
            title: 'Verified',
            id: 'verified'
          },
          {
            title: 'Not Verified',
            id: 'notVerified'
          },
          {
            title: 'No Org',
            id: 'noOrg'
          }
        ]
      },
      /*
      company: {
        selected: {
          title: 'Company',
          id: 'blank'
        },
        options: [
          {
            title: 'Assigned',
            id: 'assigned'
          },
          {
            title: 'Unassigned',
            id: 'unassigned'
          }
        ]
      }
      */
    }
  },
  mounted() {
    this.refreshList()
    this.isSuperAdmin = this.$session.isSuperAdmin
    this.loggedInUser = this.$session.currentUser
    this.$events.$on('users_refresh', (data) => {
      this.refreshList(data.users, data.totalUsers)
    })
    this.$events.$on('search_focus', () => {
      this.adding = false
    })
  },
  computed: {
    maxPage: function () {
      return Math.ceil(this.totalCount / this.pagination.pageLimit)
    },
    computedSortClass: function () {
      return function (sortId) {
        if (this.sortBy === sortId) {
          return 'sorting ' + this.sortDirection
        } else {
          return ''
        }
      }
    },
    paginatedData: function () {
      let newData = []
      let startItem =
        this.pagination.pageLimit * (this.pagination.pageNumber - 1)
      let endItem = startItem + this.pagination.pageLimit

      // Sort
      this.$util.sortColumns(this.allUsers, this.sortBy, this.sortDirection)

      // Return list
      for (startItem; startItem < endItem; startItem++) {
        if (this.allUsers[startItem]) {
          newData.push(this.allUsers[startItem])
        }
      }
      return newData
    },
    disableAccountAction: function () {
      return function (accountStatus) {
        if (accountStatus === 'active') {
          return 'Block Account'
        } else {
          return 'Unblock Account'
        }
      }
    },
    getCompanyName: function () {
      return function (companyId) {
        return this.$companyStore.getCompanyNameSync(companyId)
      }
    },
    getFormattedDate: function () {
      return function (timeStamp) {
        if (!timeStamp) return 'Never'

        let date = new Date(timeStamp)

        if (date.getFullYear() === 1969) {
          return 'Never'
        }

        return this.$moment(timeStamp).format('MM/DD/YYYY') //ddd, DD MMM YYYY HH:mm:ss
      }
    },
    accountStatus: function () {
      return function (accountStatus) {
        if (accountStatus === 'active') {
          return 'Active'
        } else {
          return 'Inactive'
        }
      }
    }
  },
  watch: {
    $route() {
      this.adding = false
    }
  },
  methods: {
    selectUnselectAll: function (e) {
      if (e.target.checked) {
        //select all
        for (let user of this.paginatedData) {
          this.selectedUsers.push(user?.id)
        }
      } else {
        //unselect all
        this.resetSelectedUsers()
      }
    },
    showAddUser: function () {
      this.$events.$emit('showInviteUserModal', {
        title: 'Resend Invite',
        companyId: this.companyId,
        user: this.user,
        showCompanies: false,
        isInvite: true,
        callback: () => {
          this.refreshList()
        }
      })
    },
    getUserRole: function (user) {
      if (user.roles.length === 0)
        return { primaryRole: '-', classVal: 'hide-icon' }
      let primaryRole = ''
      let classVal = ''
      let hasDriver = false
      for (let i = 0; i < user.roles.length; i++) {
        if (user.roles[i].role === 'org_admin') {
          classVal += ' org_admin'
          if (primaryRole != 'Super Admin') primaryRole = 'Org Admin'
        } else if (user.roles[i].role === 'acc_admin') {
          classVal += ' acc_admin'
          if (primaryRole === '') primaryRole = 'Manager'
        } else if (user.roles[i].role === 'driver') {
          classVal += ' has_driver'
          hasDriver = true
        } else if (user.roles[i].role === 'super_admin') {
          primaryRole = 'Super Admin'
          classVal = 'hide-icon'
        }
      }

      if (!primaryRole) {
        if (hasDriver) {
          primaryRole = 'Driver'
        }
      }
      return {
        primaryRole: primaryRole,
        hasDriver: hasDriver,
        classVal: classVal
      }
    },
    handleUserCompanyClick: function (index, user) {
      if (user.companies.length > 1) {
        this.$refs[`user-company-${index}`][0].changeMenuStatus()
      } else if (user.companies.length === 1) {
        this.viewCompany(user.companies[0])
      }
    },
    viewCompany: function (company) {
      setTimeout(async () => {
        try {
          await this.$session.updateSuperAdminCurrentCompany(company)
          this.$router.push(`/org/${company.id}/management/members`)
        } catch (err) {
          this.$events.$emit('error', err)
        }
      }, 250)
    },
    openAdd: function () {
      this.$events.$emit('open_add', '')
      this.adding = true
    },
    bulkImport: function () {
      this.$events.$emit('showImportModal', {
        title: 'Bulk Member Import',
        confirmText: 'Check',
        cancelText: 'Cancel',
        companyId: this.companyId,
        callback: async (confirmed, data) => {
          try {
            if (!confirmed) return
            let validation = await this.$userStore.validateUsers(
              data,
              this.companyId
            )
            return validation
          } catch (err) {
            return err
          }
        }
      })
    },
    addNew: function () {
      this.$events.$emit('showUserModal', {
        title: 'New User',
        user: {},
        showCompanies: true,
        callback: async (confirmed, data) => {
          try {
            if (!confirmed) return
            this.$events.$emit('showLoading')
            let companyId = data.company.selected.id
            if (companyId === 'new_company') {
              companyId = await this.$companyStore.add(
                data.company.selected.name
              )
              if (companyId) {
                this.$notify({
                  group: 'admin-actions',
                  title: 'Created Company',
                  text: `${data.company.selected.name} was successfully created`,
                  data: {
                    iconPath: CompanyIcon
                  }
                })
              }
            }

            let user = {
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              companyId: companyId,
              phoneNumber: data.phoneNumber,
              role: data.role.selected.title,
              password: data.password,
              verificationCode: 'skipped'
            }

            let userSuccess = await this.$userStore.add(user)
            if (userSuccess) {
              this.$notify({
                group: 'admin-actions',
                title: 'Created Account',
                text: `${data.firstName} ${data.lastName} was successfully created`,
                data: {
                  iconPath: userAddIcon
                }
              })
            }
          } catch (err) {
            return err // Handled by user modal
          } finally {
            this.$events.$emit('hideLoading')
          }
        }
      })
    },
    bulkUpdate: async function (userIds) {
      this.$events.$emit('showBulkActionsModal', {
        userIds,
        callback: (result) => {
          this.$events.$emit('hideBulkActionsModal')
          if (result) {
            this.$notify({
              group: 'admin-actions',
              title: 'Updated Account',
              text: `Bulk Action performed`,
              data: {
                iconPath: userEditIcon
              }
            })
          }
        }
      })
    },
    resetSelectedUsers: function () {
      this.selectedUsers = []
    },
    readUserRoles: async function (userId) {
      const roles = await this.$userStore.readUserRoles(userId)
      return roles
    },
    readCompaniesById: async function (companyId) {
      const company = await this.$companyStore.getCompanyName(companyId)
      return company
    },
    setSortBy: function (sortBy) {
      if (this.sortBy === sortBy) {
        if (this.sortDirection === 'up') {
          this.sortDirection = 'down'
        } else {
          this.sortDirection = 'up'
        }
      } else {
        this.sortDirection = 'up'
        this.sortBy = sortBy
      }
    },
    searchSubmitted: function () {
      this.refreshList()
    },
    userSearch: async function (val) {
      try {
        this.sortBy = 'firstName'
        if (val === '') {
          this.suggestions = []
          this.refreshList()
          return
        }

        const users = await this.$userStore.loadAll(
          {
            role: null,
            status: null,
            query: val
          },
          true
        )

        if (users.length === 0) {
          this.suggestions = [
            {
              id: 'none',
              name: 'No Results Found',
              points: 9999
            }
          ]

          return
        }

        this.suggestions = users
      } catch (err) {
        console.log(err)
      }
    },
    suggestion: function (suggestion) {
      if (suggestion.id === 'none') return
      this.suggestions = []
      this.search = suggestion.name
      this.allUsers = [suggestion]
    },
    refreshList: async function (users, count) {
      try {
        this.$events.$emit('showLoading')
        if (users) {
          this.allUsers = JSON.parse(JSON.stringify(users))
          this.totalCount = count < 1 ? 1 : count

          for (let i = 0; i < this.allUsers.length; i++) {
            let roleInfo = this.getUserRole(this.allUsers[i])
            this.allUsers[i].roleInfo = roleInfo
            this.allUsers[i].primaryRole = roleInfo.primaryRole
            this.allUsers[i].lastLoginFormatted = this.getFormattedDate(
              this.allUsers[i].lastLogin
            )
          }
        } else {
          await this.$companyStore.loadAll()
          let role = this.role.selected.id
          let status = this.status.selected.id
          let company = 'blank'//this.company.selected.id
          if (role === 'blank') role = ''
          if (status === 'blank') status = ''
          if (company === 'blank') company = ''

          await this.$userStore.loadAll(
            {
              role: role,
              status: status,
              company: company,
              query: this.search
            },
            false
          )
          this.pagination.pageNumber = 1
        }
        setTimeout(() => this.$events.$emit('hideLoading'), 500)
      } catch (err) {
        this.$events.$emit('error', err)
      }
    },
    showUserEdit: async function (user) {
      user.companyName = await this.getCompanyName(user.companyId)
      const roles = await this.readUserRoles(user.id)

      let index = 0
      for (const role of roles) {
        const company = await this.readCompaniesById(role.companyId)
        roles[index].companyName = company
        index++
      }

      let opts = {
        title: `${user.firstName} ${user.lastName}`,
        user,
        companies: this.$companyStore.companies,
        showCompanies: true,
        roles,
        initRoles: JSON.stringify(roles),
        callback: async (confirmed, data, companyData) => {
          if (!confirmed) return
          let create = null
          let del = null
          let driver = null
          let userData = null
          let companyId = null

          try {
            await new Promise((resolve, reject) => {
              try {
                this.$events.$emit('showLoading')
                userData = data.userModal.user
                if (data.company) {
                  companyId = data.company.selected.id
                }
                resolve()
              } catch (err) {
                reject(err)
              }
            })
            if (companyData) {
              create = companyData.create
              del = companyData.delete
              driver = companyData.driver

              if (del) {
                return this.$userStore.deleteUserRole(del.id)
              }
            }
            if (companyData && create) {
              return this.$userStore.createUserRole({
                userId: userData.id,
                role: create.role,
                companyId: create.companyId
              })
            }
            if (companyData && driver === true && create) {
              return this.$userStore.createUserRole({
                userId: userData.id,
                role: 'driver',
                companyId: create.companyId
              })
            }
            if (companyData && driver) {
              if (driver.id) {
                return this.$userStore.deleteUserRole(driver.id)
              }
            }
            let user = {
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              phoneNumber: data.phoneNumber,
              verificationCode: data.verificationCode,
              companyId: companyId,
              role: data.user.role
            }
            await this.$userStore.updateUserById(data.userModal.user.id, user)

            this.$notify({
              group: 'admin-actions',
              title: 'Updated Account',
              text: `${data.firstName} ${data.lastName}'s information was successfully updated`,
              data: {
                iconPath: userEditIcon
              }
            })
            this.refreshList()
          } catch (err_1) {
            console.log({ err_1 })
            if (err_1.message == 'Request failed with status code 500') {
              throw err_1
            }
            console.log(err_1)
            this.$events.$emit('hideLoading')
          }
          return this.$events.$emit('hideLoading')
        },
        getRoles: async () => await this.readUserRoles(user.id)
      }
      this.$events.$emit('showUserModal', opts)
    },
    resendInvite: function (user) {
      console.log(user)
      this.$events.$emit('showBasicModal', {
        mode: 'confirm',
        title: 'Re Send Invite',
        text: `Resend invite to ${user.firstName} ${user.lastName} at ${user.email}?`,
        callback: (resp) => {
          if (resp) {
            this.$events.$emit('showLoading')
            this.$client
              .resendInvite(user.id, user.roleInfo.primaryRole.toLowerCase())
              .then((result) => {
                this.$events.$emit('hideLoading')
                console.log(result)

                this.$notify({
                  group: 'admin-actions',
                  title: 'Message Sent',
                  text: `Message sent to ${user.email}`,
                  data: {
                    iconPath: userEditIcon
                  }
                })
                this.refreshList()
              })
              .catch((err) => {
                this.$events.$emit('error', err)

                return err
              })
          }
        },
        cancelText: 'Cancel',
        confirmText: 'Send'
      })
    },
    showVerifyUser: function (userId) {
      console.log(userId)
      let fullName = ''
      this.$userStore.getUserName(userId).then((name) => {
        fullName = name

        this.$events.$emit('showBasicModal', {
          mode: 'confirm',
          title: 'Manual Verification',
          text: `Are you sure you want to manually verify ${fullName}?`,
          secondaryText: 'This action cannot be undone',
          callback: (resp) => {
            if (resp) {
              this.$events.$emit('showLoading')
              this.$userStore
                .manuallyVerifyUser(userId)
                .then(() => {
                  this.$events.$emit('hideLoading')

                  this.$notify({
                    group: 'admin-actions',
                    title: 'Manually Verified Account',
                    text: `${fullName}'s account was successfully verified`,
                    data: {
                      iconPath: userEditIcon
                    }
                  })
                  this.refreshList()
                })
                .catch((err) => {
                  this.$events.$emit('error', err)

                  return err
                })
            }
          },
          cancelText: 'Cancel',
          confirmText: 'Verify'
        })
      })
    },
    showConfirmDelete: function (userId) {
      let fullName = ''
      this.$userStore.getUserName(userId).then((name) => {
        fullName = name

        this.$events.$emit('showBasicModal', {
          mode: 'confirm',
          title: 'Delete Confirmation',
          text: `Are you sure you want to delete ${fullName}?`,
          secondaryText: 'This action cannot be undone',
          callback: (resp) => {
            if (resp) {
              this.$events.$emit('showLoading')
              this.$userStore
                .deleteUserById(userId)
                .catch((err) => {
                  this.$events.$emit('error', err)

                  return err
                })
                .then((err) => {
                  this.$events.$emit('hideLoading')

                  if (!err) {
                    this.$notify({
                      group: 'admin-actions',
                      title: 'Deleted Account',
                      text: `${fullName}'s account was successfully deleted`,
                      data: {
                        iconPath: TrashIcon
                      }
                    })
                  }
                })
            }
          },
          cancelText: 'Cancel',
          confirmText: 'Delete'
        })
      })
    },
    showConfirmBlock: function (userId, currentStatus) {
      let newStatus = 'active'
      let action = 'Unblock'
      let fullName = ''

      this.$userStore.getUserName(userId).then((name) => {
        fullName = name

        if (currentStatus === 'active') {
          newStatus = 'inactive'
          action = 'Block'
        }

        this.$events.$emit('showBasicModal', {
          mode: 'confirm',
          title: 'Block Confirmation',
          text: `Are you sure you want to block ${fullName}?`,
          callback: (resp) => {
            if (resp) {
              this.$events.$emit('showLoading')
              console.log('update user by ID inside func')
              this.$userStore
                .updateUserById(userId, { accountStatus: newStatus })
                .catch((err) => {
                  this.$events.$emit('error', err)

                  return err
                })
                .then((err) => {
                  this.$events.$emit('hideLoading')

                  if (!err) {
                    this.$notify({
                      group: 'admin-actions',
                      title: `${action}ed Account`,
                      text: `${fullName}'s account was successfully ${action}ed`,
                      data: {
                        iconPath: TrashIcon
                      }
                    })
                  }
                })
            }
          },
          cancelText: 'Cancel',
          confirmText: action
        })
      })
    },
    pageChange: function (newPage) {
      this.pagination.pageNumber = newPage
    },
    roleFilterChanged: function (newVal) {
      if (!newVal) {
        this.role.selected = {
          title: 'Role',
          id: 'blank'
        }
      }
      this.refreshList()
    },
    statusFilterChanged: function (newVal) {
      if (!newVal) {
        this.status.selected = {
          title: 'Status',
          id: 'blank'
        }
      }
      this.refreshList()
    },
  }
}
</script>

<style lang="scss">
.user-tab {
  #user-header {
    //isSelected
    th:nth-child(1) {
      width: 3%;
    }

    //firstName
    th:nth-child(2) {
      width: 12%;
    }

    //lastName
    th:nth-child(3) {
      width: 12%;
    }

    // //phone
    // th:nth-child(4) {
    //   width: 8%;
    // }

    //email
    th:nth-child(4) {
      width: 25%;
    }

    //company
    th:nth-child(5) {
      width: 20%;
    }

    //Role
    th:nth-child(6) {
      width: 11%;
    }

    //status
    th:nth-child(7) {
      width: 7.5%;
    }

    //last signin
    th:nth-child(8) {
      width: 9%;
    }

    //menu
    th:nth-child(9) {
      width: 5%;
    }

    th .column-title {
      padding-right: 5px;
      cursor: pointer;
    }

    th .arrow {
      width: 13px;
      height: 8px;
      background-image: url('../assets/sort-arrow.png');
      display: none;
      cursor: pointer;
    }

    th .arrow.sorting {
      display: inline-block;
    }

    th .arrow.sorting.up {
      transform: rotate(180deg);
    }
  }

  tbody {
    //company
    td:nth-child(4) {
      width: 20%;
      color: #124e81;
      text-decoration: underline;
      cursor: pointer;
    }

    td.empty {
      color: #505050;
      text-decoration: none;
      cursor: default;
    }
  }

  .role-title {
    margin-top: 3px;
    float: left;
  }

  .driver-icon {
    width: 24px;
    height: 24px;
    float: left;
    background-image: url('../assets/driver_required.png');
  }
  .org_admin .driver-icon,
  .acc_admin .driver-icon {
    background-image: url('../assets/driver_passive.png');
  }

  .org_admin.has_driver .driver-icon,
  .acc_admin.has_driver .driver-icon {
    background-image: url('../assets/driver_active.png');
  }

  .hide-icon .driver-icon {
    display: none;
  }
}
</style>
