<template>
  <!-- eslint-disable vue/require-v-for-key */ -->
  <div class="search--input">
    <div class="search--input-container">
      <div
        class="icon search--input-open"
        v-on:click="search"
        v-bind:class="searching ? 'searching' : ''"
      ></div>
      <div
        class="search--input-field-container"
        v-bind:class="{ active: searching }"
      >
        <input
          id="search--input-field"
          type="text"
          v-bind:placeholder="placeholder"
          @keydown="onKeyDown"
          @keyup="onKeyUp"
          v-bind:value="value"
          v-on:blur="blur"
          v-on:input="updateValue($event.target.value)"
          v-on:focus="focus"
          autocomplete="off"
        />
      </div>
      <div
        class="icon search--input-close"
        v-if="value.length > 0"
        v-on:click="clear"
      ></div>
    </div>
    <div
      class="search--input-suggestion"
      v-if="suggestions.length !== 0 && value !== ''"
    >
      <div
        v-for="suggestion in suggestions"
        class="search--input-suggestion-item"
      >
        <div v-on:click="selectSuggestion(suggestion)">
          {{ suggestion.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let keyupTimer = null
let timerTime = 500
export default {
  name: 'SearchInput',
  props: [
    'value',
    'placeholder',
    'submitted',
    'returnValue',
    'returnSuggestion',
    'suggestions'
  ],
  data() {
    return {
      lastSearch: '',
      searching: false
    }
  },
  mounted() {
    this.$events.$on('open_add', () => {
      // eslint-disable-next-line vue/no-mutating-props
      this.value = ''
      this.blur()
    })
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    },
    search() {
      this.searching = true
      this.focus()
    },
    selectSuggestion: function (suggestion) {
      this.returnSuggestion(suggestion)
    },
    onKeyUp: function (e) {
      e.preventDefault()

      clearTimeout(keyupTimer)

      if (e.target.value.length !== 0 && e.target.value.length <= 3) return
      if (e.target.value.length == 0) {
        this.returnValue('')
        return
      }

      keyupTimer = setTimeout(
        () => this.returnValue(e.target.value.toLowerCase()),
        timerTime
      )
    },
    onKeyDown: function (e) {
      if (e.keyCode === 13) {
        e.preventDefault()
        this.attemptSubmit()
        return
      }
    },
    clear() {
      this.searching = false
      this.$emit('input', '')
      setTimeout(() => this.attemptSubmit(), timerTime)
    },
    blur() {
      if (this.value === '') {
        this.searching = false
        this.$emit('input', '')
      }
    },
    focus() {
      this.$events.$emit('search_focus', '')
    },
    attemptSubmit() {
      this.lastSearch = this.value
      this.submitted()
    }
  }
}
</script>

<style lang="scss">
@import '../scss/_variables.scss';
@import '../scss/_search-bar.scss';
</style>
