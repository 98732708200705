const status = {
  selected: {
    title: 'Expiration',
    id: null
  },
  options: [
    {
      title: 'Current',
      id: 'current'
    },
    {
      title: 'Expiring Soon',
      id: 'expiring'
    },
    {
      title: 'Expired',
      id: 'expired'
    }
  ]
}

const equipmentColumns = [
  {
    id: 'docName',
    title: 'Name'
  },
  {
    id: 'assignedTo',
    title: 'Assigned to'
  },
  {
    id: 'versionDateFormatted',
    title: 'Created'
  },
  {
    id: 'status',
    title: 'Expiration'
  },
  {
    id: 'lastUpdatedFormatted',
    title: 'Last Updated'
  },
  {
    id: 'docFormat',
    title: 'Format'
  }
]

const filterState = (id, date, instance) => {
  if (!id) {
    return true
  }

  if (id === 'current') {
    if (!instance.$util.isExpired(date) && !instance.$util.isExpiring(date)) {
      return true
    }
  }

  if (id === 'expiring') {
    if (instance.$util.isExpiring(date)) {
      return true
    }
  }

  if (id === 'expired') {
    if (instance.$util.isExpired(date)) {
      return true
    }
  }
}

export { status, equipmentColumns, filterState }
