<template>
  <div class="admin-content">
    <div id="dynamic-tabs">
      <button
        v-for="tab in tabs"
        v-bind:key="tab.component"
        v-bind:class="['tab-button', { active: currentTabFunc(tab) }]"
        v-on:click="updateTab(tab)"
      >
        {{ tab.title }}
      </button>
    </div>

    <keep-alive>
      <component :key="tabKey" :is="currentTabComponent"></component>
    </keep-alive>
  </div>
</template>

<script>
import CompanyTab from '@/components/CompanyTab.vue'
import UserTab from '@/components/UserTab.vue'

export default {
  components: {
    UserTab,
    CompanyTab
  },
  props: ['tabs', 'initialTab', 'tabType'],
  data() {
    return {
      currentTab: this.initialTab
    }
  },
  watch: {
    $route(to) {
      if (to.path.indexOf('admin') > -1) {
        let pathParts = to.path.split('/')
        let subPath = pathParts[pathParts.length - 1]
        for (let i = 0; i < this.tabs.length; i++) {
          if (subPath === this.tabs[i].path) {
            this.currentTab = this.tabs[i].path
            break
          }
        }
      }
    }
  },
  computed: {
    currentTabComponent: function () {
      let path = this.currentTab.toLowerCase()

      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i].path === path) {
          return this.tabs[i].component
        }
      }

      return ''
    },
    tabKey: function () {
      if (this.$route.params.type) {
        return this.$route.params.type
      } else {
        return this.$route.params.docType
      }
    }
  },
  methods: {
    updateTab: function (tab) {
      this.currentTab = tab.path
      this.$router.push(`/admin/${tab.path.toLowerCase()}`)
    },
    currentTabFunc: function (tab) {
      return this.currentTab === tab.path
    }
  }
}
</script>

<style lang="scss">
.admin-content {
  flex-direction: column;
  justify-content: center;
}

#dynamic-tabs {
  min-height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .tab-button {
    margin: 0px 16px 24px;
    padding: 0px;
    border: none;
    cursor: pointer;
    background: $white;
    color: $cool-grey-large;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    outline: none;
    justify-self: center;
  }
  .tab-button.active {
    border-bottom: 2px solid $light-navy;
    color: $light-navy;
  }
  .tab {
    border: 1px solid #ccc;
    padding: 10px;
  }
}

#table-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  thead th {
    height: 46px;
    text-shadow: 1px 1px 0 $black-trans;
    font-family: Barlow;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;
    letter-spacing: normal;
    color: $white;
    background-color: $light-navy;
  }

  th,
  td {
    padding-left: 16px;
    border-bottom: 1px solid $warm-grey;
    vertical-align: middle;
    text-align: left;
  }

  tbody {
    font-family: Barlow;
    font-size: 18px;
    line-height: 30px;
    color: $greyish-brown;

    tr:nth-child(odd) {
      background: $pale-blue;
    }

    td {
      height: 62px;
      word-wrap: break-word;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
    }

    td:last-child {
      cursor: pointer;
      &:hover {
        * {
          fill: $mid-blue;
        }
      }
    }
  }
}
</style>
