<template>
  <div class="truck-tab">
    <div id="table-box">
      <div class="search">
        <search-input
          v-model="search"
          v-bind:submitted="searchSubmitted"
          v-bind:returnValue="truckSearch"
          :suggestions="suggestions"
          v-bind:returnSuggestion="suggestion"
        ></search-input>

        <div class="search--filters">
          <multiselect
            class="multi-select filter-assigned-multi-select"
            label="title"
            track-by="id"
            v-model="assigned.selected"
            selectLabel
            deselectLabel
            selectedLabel
            :options="assigned.options"
            :option-width="80"
            :option-height="20"
            :searchable="false"
            :allowEmpty="true"
            open-direction="bottom"
            @input="assignedFilterChanged"
          >
            <template v-slot:singleLabel="props">
              <span class="option__title">{{ props.option.title }}</span>
              <img src="../assets/multiselect-arrow.png" class="arrow" />
            </template>
            <template v-slot:option="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.title }}</span>
              </div>
            </template>
          </multiselect>
        </div>

        <div class="search--buttons">
          <div v-if="!adding">
            <button
              class="button button-primary"
              id="add-new"
              v-on:click="openAdd"
            >
              <img src="@/assets/plus-circle.svg" alt="plus sign in circle" />
              Add
            </button>
          </div>
          <div v-if="adding">
            <button
              class="button button-primary"
              id="bulk-import"
              v-on:click="bulkImport()"
            >
              <img src="@/assets/import.png" alt="import arrow" />
              Bulk Import
            </button>
            <button
              class="button button-primary"
              id="add-new-truck"
              v-on:click="addEquipment()"
            >
              <img src="@/assets/plus-circle.svg" alt="plus sign in circle" />
              New Truck
            </button>
          </div>
        </div>
      </div>
      <div id="table-controls"></div>
      <table id="table">
        <thead id="truck-header">
          <tr id="table-header">
            <th
              v-for="column in columns"
              v-bind:key="column.id"
              class="noselect"
              v-bind:class="column.id"
            >
              <span class="column-title" v-on:click="setSortBy(column.id)">{{
                column.title
              }}</span>
              <div
                class="arrow"
                v-bind:class="computedSortClass(column.id)"
                v-on:click="setSortBy(column.id)"
              ></div>
            </th>
            <th title="menu"></th>
          </tr>
        </thead>
        <tbody v-if="paginatedData.length > 0">
          <tr
            class="table-row"
            v-for="(truck, index) in paginatedData"
            v-bind:key="truck.id"
          >
            <td>{{ truck.title }}</td>
            <td>{{ truck.assignmentString }}</td>
            <td>{{ truck.versionDateFormatted }}</td>
            <td
              v-on:click.prevent="
                $refs[`itemMenu${index}`][0].changeMenuStatus()
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="6"
                viewBox="0 0 26 6"
              >
                <g fill="#124E81" fill-rule="evenodd">
                  <circle cx="3" cy="3" r="3" />
                  <circle cx="13" cy="3" r="3" />
                  <circle cx="23" cy="3" r="3" />
                </g>
              </svg>
              <dropdown
                :key="index"
                :ref="`itemMenu${index}`"
                top="50px"
                left="-43px"
              >
                <p class="dropdown-item" v-on:click="editEquipment(truck)">
                  Edit
                </p>
                <p class="dropdown-item" v-on:click="deleteEquipment(truck)">
                  Delete
                </p>
              </dropdown>
            </td>
          </tr>
        </tbody>
      </table>
      <PaginationControls
        v-on:changePage="pageChange"
        :pageNumber="pagination.pageNumber"
        :pageMax="maxPage"
      />
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/DropDownMenu.vue'
import PaginationControls from '@/components/PaginationControls.vue'
import SearchInput from '@/components/SearchInput.vue'
import TrashIcon from '@/assets/trash.svg'
import TruckIcon from '@/assets/truck.png'
import Multiselect from 'vue-multiselect'

export default {
  name: 'truckTable',
  components: {
    PaginationControls,
    Dropdown,
    SearchInput,
    Multiselect
  },
  props: ['companyId'],
  data() {
    return {
      columns: [
        {
          id: 'title',
          title: 'Unit Number'
        },
        {
          id: 'assignmentString',
          title: 'Assigned To'
        },
        {
          id: 'versionDateFormatted',
          title: 'Created on'
        }
      ],
      allTrucks: [],
      adding: false,
      sortBy: 'title',
      sortDirection: 'up',
      pagination: {
        pageLimit: 7,
        pageNumber: 1
      },
      totalCount: this.$userStore.totalUsers,
      search: '',
      suggestions: [],
      assigned: {
        selected: {
          title: 'Assigned',
          id: 'blank'
        },
        options: [
          {
            title: 'Is Assigned',
            id: 'assigned'
          },
          {
            title: 'Not Assigned',
            id: 'unassigned'
          }
        ]
      }
    }
  },
  created() {
    this.refreshList()

    this.$events.$on('truck_refresh', (data) => {
      this.refreshList(data.equipment, data.totalEquipment)
    })

    this.$events.$on('search_focus', () => {
      this.adding = false
    })
  },
  watch: {
    $route() {
      this.adding = false
    }
  },
  computed: {
    maxPage: function () {
      return Math.ceil(this.totalCount / this.pagination.pageLimit)
    },
    computedSortClass: function () {
      return function (sortId) {
        if (this.sortBy === sortId) {
          return 'sorting ' + this.sortDirection
        } else {
          return ''
        }
      }
    },
    paginatedData: function () {
      let newData = []
      let startItem =
        this.pagination.pageLimit * (this.pagination.pageNumber - 1)
      let endItem = startItem + this.pagination.pageLimit

      // Sort
      this.$util.sortColumns(this.allTrucks, this.sortBy, this.sortDirection)

      // Return list
      for (startItem; startItem < endItem; startItem++) {
        if (this.allTrucks[startItem]) {
          newData.push(this.allTrucks[startItem])
        }
      }
      return newData
    }
  },
  methods: {
    getFormattedDate: function (timeStamp) {
      if (!timeStamp) return 'Never'

      let date = new Date(timeStamp)

      if (date.getFullYear() === 1969) {
        return 'Never'
      }
      return this.$moment(timeStamp).format('MM/DD/YYYY')
    },
    openAdd: function () {
      this.$events.$emit('open_add', '')
      this.adding = true
    },
    refreshList: async function (equip, count) {
      try {
        this.$events.$emit('showLoading')
        if (equip) {
          this.allTrucks = JSON.parse(JSON.stringify(equip))
          this.totalCount = count < 1 ? 1 : count

          for (let i = 0; i < this.allTrucks.length; i++) {
            this.allTrucks[i].versionDateFormatted = this.getFormattedDate(
              this.allTrucks[i].versionDate
            )
          }
        } else {
          let assigned = this.assigned.selected.id
          if (assigned === 'blank') assigned = ''

          await this.$equipmentStore.loadAll(this.companyId, {
            equipmentType: 'truck',
            assigned: assigned,
            query: this.search
          })
          this.pagination.pageNumber = 1
        }
        setTimeout(() => {
          this.$events.$emit('hideLoading')
        }, 500)
      } catch (err) {
        this.$events.$emit('error', err)
      }
    },
    bulkImport: function () {
      this.$events.$emit('showImportModal', {
        title: 'Bulk Truck Import',
        confirmText: 'Check',
        cancelText: 'Cancel',
        companyId: this.companyId,
        callback: async (confirmed, data) => {
          try {
            if (!confirmed) return
            let validation = await this.$equipmentStore.validateEquipment(
              data,
              this.companyId,
              'truck'
            )
            return validation
          } catch (err) {
            this.$events.$emit('hideImportModal', err)
            this.$events.$emit('error', err)
          }
        }
      })
    },
    setSortBy: function (sortBy) {
      if (this.sortBy === sortBy) {
        if (this.sortDirection === 'up') {
          this.sortDirection = 'down'
        } else {
          this.sortDirection = 'up'
        }
      } else {
        this.sortDirection = 'up'
        this.sortBy = sortBy
      }
    },
    searchSubmitted: function () {
      this.refreshList()
    },
    truckSearch: async function (val) {
      try {
        this.sortBy = 'title'
        if (val === '') {
          this.suggestions = []
          this.refreshList()
          return
        }
        let assigned = this.assigned.selected.id
        if (assigned === 'blank') assigned = ''

        const equipment = await this.$equipmentStore.loadAll(
          this.companyId,
          {
            equipmentType: 'truck',
            assigned: assigned,
            query: val
          },
          true
        )

        if (equipment.length === 0) {
          this.suggestions = [
            {
              id: 'none',
              name: 'No Results Found',
              points: 9999
            }
          ]

          return
        }

        this.suggestions = equipment
      } catch (err) {
        console.log(err)
        this.$events.$emit('hideLoading')
      }
    },
    suggestion: function (suggestion) {
      if (suggestion.id === 'none') return
      this.search = suggestion.name.toLowerCase()
      this.suggestions = []
      this.allTrucks = [suggestion]
    },
    pageChange: function (newPage) {
      this.pagination.pageNumber = newPage
    },
    assignedFilterChanged: function (newVal) {
      if (!newVal) {
        this.assigned.selected = {
          title: 'Assigned',
          id: 'blank'
        }
      }
      this.refreshList()
    },
    addEquipment: function () {
      this.$events.$emit('showEquipmentModal', {
        title: 'New Truck',
        companyId: this.companyId,
        importType: 'truck',
        callback: async (confirmed, data) => {
          try {
            if (!confirmed) return

            this.$events.$emit('showLoading')
            let newData = await this.$equipmentStore.add({
              title: data.unitNumber,
              ownerId: this.companyId,
              type: 'truck'
            })

            if (data.assignments) {
              await this.$assignmentStore.addAssignment(
                newData.id,
                data.assignments
              )
            }

            this.refreshList()

            this.$notify({
              group: 'admin-actions',
              title: `Truck Added`,
              text: `<span style="font-weight: 500;">Truck (${data.unitNumber})</span> was successfully added`,
              data: {
                iconPath: TruckIcon
              }
            })
          } catch (err) {
            this.$events.$emit('error', err)
          }
        }
      })
    },
    editEquipment: function (equipment) {
      // First get the previous assignments
      let previousAssignments = JSON.parse(
        JSON.stringify(equipment.assignments)
      )
      let parsedAssignments = []
      let prevAssignmentMap = {}
      for (let i = 0; i < previousAssignments.length; i++) {
        prevAssignmentMap[previousAssignments[i].driverId] =
          previousAssignments[i]
        parsedAssignments.push({
          id: previousAssignments[i].driverId,
          title:
            previousAssignments[i].driver.firstName +
            ' ' +
            previousAssignments[i].driver.lastName
        })
      }

      // Call them modal
      this.$events.$emit('showEquipmentModal', {
        title: 'Edit Truck',
        unitNumber: equipment.title,
        companyId: equipment.ownerId,
        assignments: parsedAssignments,
        importType: 'truck',
        callback: async (confirmed, data) => {
          try {
            if (!confirmed) return

            this.$events.$emit('showLoading')

            // Get the changes and update the equipment
            if (equipment.title !== data.unitNumber) {
              await this.$equipmentStore.updateEquipmentById(equipment.id, {
                title: data.unitNumber
              })
            }
            let changes = this.$util.getArrayChanges(
              parsedAssignments,
              data.assignments
            )
            let deletedAssignments = []
            for (let i = 0; i < changes.removed.length; i++) {
              deletedAssignments.push(
                prevAssignmentMap[changes.removed[i].id].id
              )
            }
            if (deletedAssignments.length > 0) {
              await this.$assignmentStore.deleteAssignments(deletedAssignments)
            }

            if (
              data.assignments &&
              data.assignments.id &&
              !prevAssignmentMap[data.assignments.id]
            ) {
              await this.$assignmentStore.addAssignment(
                equipment.id,
                data.assignments
              )
            }

            this.refreshList()

            this.$notify({
              group: 'admin-actions',
              title: `Truck Updated`,
              text: `<span style="font-weight: 500;">Truck (${data.unitNumber})</span> was successfully updated`,
              data: {
                iconPath: TruckIcon
              }
            })
          } catch (err) {
            this.$events.$emit('error', err)
          }
        }
      })
    },
    deleteEquipment: function (equipment) {
      this.$events.$emit('showBasicModal', {
        mode: 'confirm',
        title: 'Delete Confirmation',
        text: `Are you sure you want to delete truck ${equipment.title} and documents assigned to it?`,
        secondaryText:
          'Drivers associated to this truck will be not be able to access its documents in the app.',
        callback: (resp) => {
          if (resp) {
            this.$events.$emit('showLoading')
            this.$equipmentStore
              .deleteEquipmentById(equipment.id)
              .catch((err) => {
                this.$events.$emit('error', err)

                return err
              })
              .then((err) => {
                return this.refreshList().then(() => {
                  this.$events.$emit('hideLoading')

                  if (!err) {
                    this.$notify({
                      group: 'admin-actions',
                      title: 'Deleted Truck',
                      text: `${equipment.title} was successfully deleted`,
                      data: {
                        iconPath: TrashIcon
                      }
                    })
                  }
                })
              })
              .catch((err) => {
                this.$events.$emit('error', err)
              })
          }
        },
        cancelText: 'Cancel',
        confirmText: 'Confirm'
      })
    }
  }
}
</script>

<style lang="scss">
.truck-tab {
  #truck-header {
    //unit number
    th:nth-child(1) {
      width: 30%;
    }

    //assigned to
    th:nth-child(2) {
      width: 40%;
    }

    //created on
    th:nth-child(3) {
      width: 25%;
    }

    //menu
    th:nth-child(4) {
      width: 5%;
    }

    th .column-title {
      padding-right: 5px;
      cursor: pointer;
    }

    th .arrow {
      width: 13px;
      height: 8px;
      background-image: url('../assets/sort-arrow.png');
      display: none;
      cursor: pointer;
    }

    th .arrow.sorting {
      display: inline-block;
    }

    th .arrow.sorting.up {
      transform: rotate(180deg);
    }
  }
}
</style>
