import Util from '../service/util.js'

export function exportTableToCSV(docs) {
  const rows = []
  let util = new Util()

  rows.push(['Title', 'Type', 'Assigned To', 'Expiration'])

  for (let i = 0; i < docs.length; i++) {
    if (
      util.isExpired(docs[i].expiration) ||
      util.isExpiring(docs[i].expiration)
    ) {
      rows.push([
        docs[i].docName,
        docs[i].type,
        docs[i].assignedTo,
        docs[i].expiration
      ])
    }
  }

  let csvContent =
    'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n')

  var encodedUri = encodeURI(csvContent)
  var link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', 'my_data.csv')
  document.body.appendChild(link) // Required for FF

  link.click() // This will download the data file named "my_data.csv".
}
