<template>
  <div class="account-content">
    <div v-show="isSuperAdmin" class="tab-spacer"></div>
    <div v-show="!isSuperAdmin" id="dynamic-tabs">
      <button
        v-for="tab in tabs"
        v-bind:key="tab.component"
        v-bind:class="['tab-button', { active: currentTabFunc(tab) }]"
        v-on:click="updateTab(tab)"
      >
        {{ tab.title }}
      </button>
    </div>

    <keep-alive>
      <component
        :key="tabKey"
        :companyId="companyId"
        :is="currentTabComponent"
      ></component>
    </keep-alive>
  </div>
</template>

<script>
import PersonalInfoTab from '@/components/PersonalInfoTab.vue'
import BillingTab from '@/components/BillingTab.vue'

export default {
  components: {
    PersonalInfoTab,
    BillingTab
  },
  props: ['tabs', 'initialTab', 'tabType', 'companyId'],
  data() {
    return {
      currentTab: this.initialTab,
      isSuperAdmin: true
    }
  },
  created() {
    // If already loaded, update the state, otherwise wait for an event
    let userData = this.$session.getUserData()
    if (userData.dataLoaded) {
      this.isSuperAdmin = userData.isSuperAdmin
    }
    this.$events.$on('admin_updated', (args) => {
      this.isSuperAdmin = args.isSuperAdmin
    })
  },
  watch: {
    $route(to) {
      if (to.path.indexOf('account') > -1) {
        let pathParts = to.path.split('/')
        let subPath = pathParts[pathParts.length - 1]
        for (let i = 0; i < this.tabs.length; i++) {
          if (subPath === this.tabs[i].path) {
            this.currentTab = this.tabs[i].path
            break
          }
        }
      }
    }
  },
  computed: {
    currentTabComponent: function () {
      let path = this.currentTab.toLowerCase()

      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i].path === path) {
          return this.tabs[i].component
        }
      }

      return ''
    },
    tabKey: function () {
      return this.$route.params.type
    }
  },
  methods: {
    updateTab: function (tab) {
      this.currentTab = tab.path
      this.$router.push(`/account/${tab.path}`)
    },
    currentTabFunc: function (tab) {
      return this.currentTab === tab.path
    }
  }
}
</script>

<style lang="scss">
.account-content {
  flex-direction: column;
  justify-content: center;

  .tab-spacer {
    height: 72px;
  }

  #dynamic-tabs {
    min-height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .tab-button {
      margin: 0px 16px 43px;
      padding: 0px;
      border: none;
      cursor: pointer;
      background: $white;
      color: $cool-grey-large;
      font-family: Poppins;
      font-size: 30px;
      font-weight: 500;
      text-align: center;
      outline: none;
      justify-self: center;
    }
    .tab-button.active {
      border-bottom: 2px solid $light-navy;
      color: $light-navy;
    }
    .tab {
      border: 1px solid #ccc;
      padding: 10px;
    }
  }
}
</style>
